import {
  ApplicationErrorBoundary,
  ApplicationErrorBoundaryProps,
} from '@shiftsmartinc/remix-utils';

export interface GlobalErrorBoundaryProps
  extends ApplicationErrorBoundaryProps {}

export const GlobalErrorBoundary = (props: GlobalErrorBoundaryProps) => {
  // Hooks

  // Setup

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <ApplicationErrorBoundary data-testid="GlobalErrorBoundary" {...props} />
  );
};
